/*Profile Card 3*/

* {
  font-family: "Poppins", sans-serif;
}

body {
  background-color: black;
}

.darken {
  position: absolute;
  width: 100%;
  background-color: #f7f7f7;
}

.login-form {
  color: #fff;
  margin: 0 auto;
  /*box-shadow: 0px 0px 15px 2px #f0f0f0;*/
  padding: 30px;
  border-radius: 8px;

  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.login-form label {
  color: #000;
}

.userAvatar {
  height: 200px;
  border-radius: 100%;
  width: 200px;
}

.compant-avatar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-avatar {
  height: auto;
  width: 200px;
}

.company-cards i {
  background: #000000;
  padding: 7px;
  border-radius: 100%;
  color: #fff;
}

.avatars {
  height: 15rem;
  background-size: cover;
  background-position: center;
}

.profile-section {
  font-weight: 700;
  font-size: 25px;
  padding-bottom: 8px;
}

.specialties-list {
  list-style-type: none;
  width: fit-content;
  margin: 0 auto;
}

.specialities-listitem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.specialities li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.specialities span {
  color: #000;
  font-weight: 600;
  background: rgb(54 81 207 / 15%);
  padding: 4px;
  border-radius: 10px;
  margin-right: 8px;
  margin-top: 8px;
}

.w3-display-middle h1 {
  color: red;
}

.w3-display-middle h6 {
  color: red;
  text-decoration: underline;
}

.w3-display-middle {
  text-align: center;
}

.signedInUser {
  color: #fff;
  font-weight: 700;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-user {
  padding-right: 5px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.fa-file-pdf {
  font-size: 35px;
}

.bg-dark {
  background-color: #000;
}

.profile-card-3 {
  font-family: "Open Sans", Arial, sans-serif;
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  text-align: center;
  height: 380px;
  border: none;
}
.profile-card-3 .background-block {
  float: left;
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.profile-card-3 .background-block .background {
  width: 100%;
  vertical-align: top;
  opacity: 0.9;

  -webkit-transform: scale(1.8);
  transform: scale(2.8);
}

.card {
  border: unset !important;
}

.profile-card-3 .card-content a {
  text-decoration: none;
}

.profile-card-3 .card-content {
  width: 100%;
  padding: 15px 25px;
  color: #fff;
  float: left;
  background: #000;
  height: 50%;
  border-radius: 0 0 5px 5px;
  position: relative;
  z-index: 9999;
}
.profile-card-3 .card-content::before {
  content: "";
  background: #000;
  width: 120%;
  height: 100%;
  left: 11px;
  bottom: 51px;
  position: absolute;
  z-index: -1;
  transform: rotate(-13deg);
}
.profile-card-3 .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 7rem;
  opacity: 1;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 1);
  transform: translate(-50%, 0%);
  z-index: 99999;
  height: 7rem;
  object-fit: cover;
}
.profile-card-3 h2 {
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 25px;
}
.profile-card-3 h2 small {
  display: block;
  font-size: 15px;
  margin-top: 10px;
}
.profile-card-3 i {
  display: inline-block;
  font-size: 16px;
  color: #232323;
  text-align: center;
  border: 1px solid #232323;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin: 0 5px;
}
.profile-card-3 .icon-block {
  color: #909090;
  display: flex;
  font-size: 14px;
  font-weight: 800;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.profile-card-3 .icon-block a {
  text-decoration: none;
}
.profile-card-3 i:hover {
  background-color: #232323;
  color: #fff;
  text-decoration: none;
}

.edit_btn {
  background: #ffffff;
  border: unset;
  border-radius: 6px;
  font-weight: 600;
  width: 7rem;
  height: 38px;
  border-bottom: 3px solid #a3a3a3;
}

.edit_btn:hover {
  background: #e4e4e4;
  border: unset;
  border-radius: 6px;
  font-weight: 600;
  width: 7rem;
  height: 38px;
  border-bottom: 3px solid #adadad;
}

.button-primary {
  background: #3a6fffd9;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  height: 38px;
  width: 99px;
  border-bottom: 3px solid #1e3e98d9;
  color: #fff;
}

.logo {
  background-color: #000;
  height: 10rem;
  border-radius: 10px;
}

@media screen and (min-width: 767px) {
  .login-form {
    width: 25%;
  }
  .logo {
    height: 10rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .login-form {
    width: 50%;
  }
  .logo {
    height: 10rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .login-form {
    width: 100%;
  }

  /* .logo {
    height: 5rem;
  } */

  .logo-icon {
    height: 65px !important;
    margin: 0 !important;
  }

  .logout-button {
    width: 120px !important;
  }

  .mt-3-mob {
    margin-bottom: 30px;
  }

  .footer-logo {
    height: 30px !important;
  }
}

.logo-wrapper {
  text-align: center;
}

.logout-button {
  font-weight: 600 !important;
  border-bottom: 3px solid #8f1f2a !important;
  background-color: #a0222f Impo !important;
  color: #fff;
  width: 13rem;
  margin-right: 18px;
}

.login-button {
  font-weight: 600;
  border-bottom: 3px solid #8f1f2a;
}

.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crouses-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-wrapper {
  max-width: 185px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: #f7f7f7;
  padding: 3px;
}

.loggedin {
  color: #fff !important;
  width: 8rem !important;
}

.loggedin:hover {
  text-decoration: underline;
}

.company-cards {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 3px #f0f0f0;
  height: 100%;
}

.specialties-list {
  list-style-type: none;
  padding-left: 10px;
}

.user-profile-card-info {
  text-align: center;
  box-shadow: 0px 0px 8px 1px #f1f1f1;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.user-profile-card {
  text-align: center;
  box-shadow: 0px 0px 5px 2px #eaeaea;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}

.subheading {
  color: #000;
  font-size: 30px;
}

.small-heading {
  color: #000;
  font-size: 14px;
  margin: 0 auto;
}

.company-contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexify {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.logo-icon {
  height: 85px;
  margin: 0 10px 0px 10px;
}

.nav-logo {
  width: 90px !important;
  height: auto !important;
}

.blackout {
  background-color: #000;
  color: #fff;
}

.certificate {
  width: 4rem;
  margin-bottom: 10px;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 4px 2px #f7f7f7;
  padding: 5px;
}

.modal {
  z-index: 999999 !important;
}

.presentation-partial {
  font-size: 12px;
  margin-top: 1rem;
}

.presentation-partial span {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.h-full {
  height: -webkit-fill-available !important;
}

.action {
  text-decoration: none;
}

.actions {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 13%);
}

.footer-logo {
  height: 60px;
}

.logos-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-black {
  background-color: #111111;
  height: fit-content;
}

.gap-100 {
  min-height: 90vh;
}

.grid {
  display: grid;
  justify-content: center;
  text-align: center;
}

.fa-trash {
  cursor: pointer;
}

.certs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bg-light {
  background-color: #eaeaea;
}

.h-10 {
  min-height: 18rem;
}

.bg-black {
  background-color: black;
}

.text-white {
  color: #f7f7f7;
}

.bg-black-200 {
  background-color: #0c0c0c;
}

.text-sm {
  font-size: 13px;
}
